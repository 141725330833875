import React from "react"
import { Link } from "gatsby"
import Layout from "../components/en/ENlayout"
import Image from "../components/image"
import SEO from "../components/seo"

const ENIndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Inicio en ingles</h1>
  </Layout>
)

export default ENIndexPage
